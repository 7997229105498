












import { ArticleModel, ProductTypeModel } from "@/libs/Api";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";
import { articlesStore } from "@/libs/articles/+state/store";
import { CrudAction } from "@/libs/core/+state/models/crud-action";
import { createCrudQueryPayload } from "@/libs/core/+state/models/crud-query-payload";

@Component({})
export default class ArticleSelect extends Vue {
    @Prop({ default: null }) label!: string;
    @VModel() innerValue!: string;
    @Prop({ default: true }) clearable!: boolean;
    @Prop({ default: false }) required!: boolean;

    get items(): ProductTypeModel[] {
        return articlesStore.useGetter(CrudGetter.Data, "ArticleSelect");
    }

    mounted(): void {
        articlesStore.dispatch(
            CrudAction.GetAll,
            createCrudQueryPayload<ArticleModel>(
                { field: "name", index: 1, order: "asc" },
                undefined,
                undefined,
                false,
                "ArticleSelect"
            )
        );
    }
}
