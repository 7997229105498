




















































































































import {Component, Prop, Watch} from 'vue-property-decorator';

import VueApexCharts from 'vue-apexcharts';
import {productActivitiesStore, productsStore} from '@/libs/products/+state/store';
import {CrudAction, CrudReponse, CrudResponseAction} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {partiesStore, partyProductsStore} from '@/libs/parties/+state/store';
import {Constants} from '@/libs/constants/constants';
import {
  PartyModel,
  PartyProductModel,
  ProductActivityModel,
  ProductModel,
  StateOfCapitalModel,
  StateOfCapitalYearModel,
  TransactionModel,
  ExchangeRateModel
} from '@/libs/Api';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import NWDialog from '@/libs/clients/components/NWDialog.vue';
import {transactionsStore} from '@/libs/transactions/+state/store';
import {exchangeRatesStore} from '@/libs/exchange-rates/+state/store';
import moment from 'moment';
import {stateOfCapitalStore, stateOfCapitalYearStore} from '@/libs/state-of-capital/+state/store';
import Page from "@/Page.vue";

@Component({
    components: {
        VueApexCharts,
        NWDialog
    }
})
export default class AccountProductWealth extends Page {
    
    headersTransactions = [
        { text: 'Datum', align: 'left', sortable: false, value: 'time', type: "date", width: "20%" },
        { text: 'Vklad', align: 'left', sortable: false, value: 'deposit', suffix: "Kč", type: "money", width: "20%" },
        { text: 'Výběr', align: 'left', sortable: false, value: 'withdrawal', suffix: "Kč", type: "money" },
    ];
    fundHeaders = [
        { text: 'Datum', align: 'left', sortable: false, value: 'fromDate', type: "date", width: "20%", format: "MMMM YYYY" },
        { text: 'Zhodnocení fondu aktuální', align: 'left', sortable: false, value: 'rate', suffix: "", type: "number", width: "20%" },
        { text: 'Zhodnocení fondu kumulativní', align: 'left', sortable: false, value: 'rateCumulative', suffix: "", type: "number" },
    ];
    items = [];
    product: ProductModel | null = null;
    partyProduct: PartyProductModel | null = null;
    clientEvaluations: Array<any> = [];
    year = moment().year();
    history: TransactionModel[] = [];
    exchangeRates: ExchangeRateModel[] = [];

    get years () {
        let d = 2023;
        const out: Array<number> = [];
        while (d <= moment().year()) {
            out.push(d);
            d++;
        }
        return out;
    }

    get ProductActivities () : ProductActivityModel[] {
        return productActivitiesStore.useGetter(CrudGetter.Data);
    }

    get transactions () : TransactionModel[] {
        return this.history;
    }

    get stateOfCapital(): number {
      const transactions = this.history;
      return transactions.sum(x => x.deposit ?? 0 - x.withdrawal ?? 0);
    }

    get totalDeposit(): number {
      const transactions = this.history;
      return transactions.sum(x => x.deposit ?? 0);
    }

    get totalWithdrawal(): number {
      const transactions = this.history;
      return transactions.sum(x => x.withdrawal ?? 0);
    }

    get showChart(): boolean {
        return this.exchangeRates.length > 0;
    }

    get fundExchangeRates() {
        return this.getFundExchangeRates();
    }

    get currentInvestmentValue () {
        const exchangeRate = this.exchangeRates.where(x => moment(x.fromDate).startOf("month").isSameOrBefore(moment().startOf("month"))).orderByDescending(x => moment(x.fromDate).toDate().getTime()).toArray().firstOrDefault();
        const shareSum = this.history.sum(x => x.share ?? 0);
        return shareSum * (exchangeRate.rate ?? 1);
    }

    get currentInvestmentPercentValue () {
        
        const exchangeRate = this.exchangeRates.where(x => moment(x.fromDate).startOf("month").isSameOrBefore(moment().startOf("month"))).orderByDescending(x => moment(x.fromDate).toDate().getTime()).toArray().firstOrDefault();
        const shareSum = this.history.sum(x => x.share ?? 0);
        const transactions = this.history;

        const currentInvestmentValue = shareSum * (exchangeRate.rate ?? 1) + transactions.sum(x => x.withdrawal ?? 0);
        const stateOfCapital = transactions.sum(x => x.deposit ?? 0 - x.withdrawal ?? 0);

        if (stateOfCapital <= 0)
            return 0.00;

        const value = Number(((currentInvestmentValue / stateOfCapital - 1) * 100).toFixed(2));
        return Math.abs(value) == 0 ? 0 : value;

    }
    
    get chartSeries () : any[] {
        if (this.partyProduct == null)
            return [];
        let dataSeriesFund: Array<any> = [ [ moment(this.year + '-01-01').add(-1, 'month').startOf("month").toDate().getTime(), 0 ] ];
        let fromDate = moment(this.year + '-01-01').startOf("year").toDate();
        let clientFromDate = moment(this.year + '-01-01').startOf("year").toDate();
        let toDate = moment(this.year + '-12-31').endOf("year").toDate();
        if (moment(fromDate).startOf("month").isBefore(moment(this.partyProduct!.dateOfSignature))) {
            clientFromDate = moment(this.partyProduct!.dateOfSignature).startOf("month").toDate();
        }
        if (moment(toDate).startOf("month").isAfter(moment(this.partyProduct!.dateOfEnding))) {
            toDate = moment(this.partyProduct!.dateOfEnding).endOf("month").toDate();
        }
        const fundExchangeRates = this.getFundExchangeRates().where(x => moment(x.fromDate).startOf("month").isSameOrAfter(moment(clientFromDate).startOf("month")) && moment(x.fromDate).startOf("month").isSameOrBefore(moment(toDate).startOf("month")));
        dataSeriesFund = dataSeriesFund.concat(fundExchangeRates.select(x => [ moment(x.fromDate).startOf("month").toDate().getTime(), x.rateCumulativeValue / 100 ]).orderBy(x => x[0]).toArray());
        return [
            {
                name: 'Zhodnocení fondu kumulativní',
                data: dataSeriesFund
            }
        ];
    }

    get pendingTransactions () : boolean {
        return transactionsStore.useGetter(CrudGetter.Pending);
    }

    get savingTransactions () : boolean {
        return transactionsStore.useGetter(CrudGetter.Saving);
    }

    get client () : PartyModel {
        return partiesStore.useGetter(CrudGetter.Detail, this.id);
    }
    
    get stateOfCapitalYear () : StateOfCapitalYearModel[] {
        return stateOfCapitalYearStore.useGetter(CrudGetter.Data);
    }

    @Prop() id!: string;
    @Prop() partyProductId!: string;
    
    getFundExchangeRates(): { fromDate: string, rate: string, rateCumulative: string, rateValue: number, rateCumulativeValue: number }[] {
        
        const newAlgorithmFromDate = moment("2023-07-01").toDate();
        
        let oldAlgorithmItems: { fromDate: string, rate: string, rateCumulative: string, rateValue: number, rateCumulativeValue: number }[] = [];
        let newAlgorithmItems: { fromDate: string, rate: string, rateCumulative: string, rateValue: number, rateCumulativeValue: number }[] = [];
        let fundValue = 1;
        
        let previousExchangeRate = 1;
        let cumulativeFundValue = 0;
        
        this.exchangeRates.where(x => moment(x.fromDate).toDate() >= moment("2023-01-01T00:00:00").startOf("day").toDate() && moment(x.fromDate).startOf("month").isBefore(moment(newAlgorithmFromDate).startOf("month"))).orderBy(x => moment(x.fromDate).toDate().getTime()).toArray().forEach((x) => {
            const exchangeRate = x!.rate;
            let monthFundValueRate = 0;
            monthFundValueRate = (exchangeRate / previousExchangeRate) - 1;
            previousExchangeRate = exchangeRate;
            cumulativeFundValue += monthFundValueRate;
            oldAlgorithmItems.push({
                fromDate: x.fromDate,
                rate: Number(monthFundValueRate * 100).toFixed(2) + " %",
                rateCumulative: Number(cumulativeFundValue * 100).toFixed(2) + " %",
                rateValue: Number(Number(monthFundValueRate * 100).toFixed(2)),
                rateCumulativeValue: Number(Number(cumulativeFundValue * 100).toFixed(2))
            });
            fundValue = monthFundValueRate;
        });
        oldAlgorithmItems = oldAlgorithmItems.orderByDescending(x => moment(x.fromDate).toDate().getTime()).toArray();
        
        previousExchangeRate = 1;
        cumulativeFundValue = 0;
        
        this.exchangeRates.where(x => moment(x.fromDate).toDate() >= moment("2023-01-01T00:00:00").startOf("day").toDate() && moment(x.fromDate).startOf("month").isSameOrBefore(moment().startOf("month"))).orderBy(x => moment(x.fromDate).toDate().getTime()).toArray().forEach((x) => {
            const exchangeRate = x!.rate;
            let monthFundValueRate = 0;
            monthFundValueRate = (exchangeRate - previousExchangeRate);
            previousExchangeRate = exchangeRate;
            cumulativeFundValue += monthFundValueRate;
            newAlgorithmItems.push({
                fromDate: x.fromDate,
                rate: Number(monthFundValueRate * 100).toFixed(2) + " %",
                rateCumulative: Number(cumulativeFundValue * 100).toFixed(2) + " %",
                rateValue: Number(Number(monthFundValueRate * 100).toFixed(2)),
                rateCumulativeValue: Number(Number(cumulativeFundValue * 100).toFixed(2))
            });
            fundValue = monthFundValueRate;
        });
        newAlgorithmItems = newAlgorithmItems.where(x => moment(x.fromDate).startOf("month").isSameOrAfter(moment(newAlgorithmFromDate).startOf("month"))).orderByDescending(x => moment(x.fromDate).toDate().getTime()).toArray();
        
        if (oldAlgorithmItems.any() && newAlgorithmItems.any()) {
            newAlgorithmItems.last().rateValue = newAlgorithmItems.last().rateCumulativeValue - oldAlgorithmItems.first().rateCumulativeValue;
            newAlgorithmItems.last().rate = Number(newAlgorithmItems.last().rateValue).toFixed(2) + " %";
        }
        
        return newAlgorithmItems.concat(oldAlgorithmItems);
        
    }
    
    mounted () {
        this.load();
    }

    load () {
        this.loadProduct();
        this.loadProductActivities();
        this.loadExchangeRates();
    }

    loadProduct () {
        productsStore.dispatch(CrudAction.Get, { id: Constants.WealthProductId, key: Constants.WealthProductId });
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === productsStore.getActionName(CrudResponseAction.GetSuccess)) {
                subscriber();
                self.loadPartyProduct();
            }
        });
    }

    loadPartyProduct () {
        partyProductsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload(undefined, [
            { field: "id", op: "eq", comparand: this.partyProductId }
        ]));
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === partyProductsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                self.makeProducts();
                if (this.partyProduct != null) {
                    self.loadTransactions();
                }
            }
        });
    }

    loadTransactions () {
        transactionsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<TransactionModel>([{field: "time", order: "desc", index: 0}], [
            { field: "partyProductId", op: "eq", comparand: this.partyProduct!.id! }
        ], undefined, false, this.partyProduct!.id!));
        this.subscribe(transactionsStore, CrudReponse.GetAll, this.partyProduct!.id!).then((e: TransactionModel[]) => {
          this.history = e;
        }).catch((e) => {
          console.log(e);
        });
    }

    makeProducts () {
        this.product = productsStore.useGetter(CrudGetter.Detail, Constants.WealthProductId);
        const partyProducts = partyProductsStore.useGetter(CrudGetter.Data);
        if (partyProducts.length > 0) {
            this.partyProduct = partyProducts[0];
        }
    }

    loadProductActivities () {
        productActivitiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ProductActivityModel>([
            { field: "createdAt", order: "desc", index: 0 }
        ], [
            { field: "productId", op: "eq", comparand: Constants.WealthProductId }
        ]))
    }

    loadExchangeRates() {
        exchangeRatesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ExchangeRateModel>([
          { field: "createdAt", order: "desc", index: 0 }
        ], [
          { field: "productId", op: "eq", comparand: Constants.WealthProductId }
        ], undefined, false, Constants.WealthProductId))
        this.subscribe(exchangeRatesStore, CrudReponse.GetAll, Constants.WealthProductId).then((e: ExchangeRateModel[]) => {
          this.exchangeRates = e;
        }).catch((e) => {
          console.log(e);
        });
    }

    changed () {
        this.load();
    }
    
    removed () {
        this.$router.push({ name: "ClientProducts", params: { id: this.id } })
    }
    
    get chartOptions() {
	    let maxY = this.chartSeries.firstOrDefault()?.data.select(x => x[1]).toArray().max() ?? 0;
	    if (maxY == 0) {
		    maxY = 0.2
	    }
        const n = {
            chart: {
                height: 300,
                type: 'line',
                background: "transparent",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                tickAmount: 6,
                labels: {
                    formatter: (value: Date) => {
                        if (moment(value).isBefore(moment(this.year + "-01-01 00:00:00"))) {
                            return "Zahájení roku";
                        }
                        else {
                            return moment(value).format("MM.YYYY");
                        }
                    }
                },
                min: moment(this.year + "-01-01 00:00:00").add(-1, "month").toDate().getTime(),
                max: moment(this.year + "-12-31 23:59:59").toDate().getTime()
            },
            yaxis: [
                {
                    labels: {
                        formatter: function (value: number) {
                            return Number(value * 100).toFixed(2).concat(" %");
                        }
                    },
                    forceNiceScale: true,
	                max: maxY * 1.1
                }
            ],
            theme: {
                mode: "dark"
            },
            dataLabels: {
                enabled: false
            }
        };
        return n;
    }
}
