






















































































































import {
	GroupByProjectionDefinition,
	ProjectionAggregationEnum,
	QueryOperator, ShareLogModel, ShareModel,
	TransactionModel,
	WalletModel,
	WalletTransactionModel,
	WalletTransactionTypeEnum
} from '@/libs/Api';
import {Component, Ref, Watch} from 'vue-property-decorator';
import {CrudAction, CrudReponse} from '@/libs/core/+state/models/crud-action';
import {createCrudQueryPayload, createGrouppedQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';

import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import {Guid} from '@/libs/common/functions/guid';
import {Constants} from '@/libs/constants/constants';
import {walletTransactionsStore} from '@/libs/wallet-transactions/+state/store';
import Page from '@/Page.vue';
import {walletsStore} from "@/libs/wallets/+state/store";
import ApiService from '@/libs/core/api-service';
import {CrudDataStats} from "@/libs/core/+state/models/crud-data-stats";
import {sharesStore} from "@/libs/shares/+state/store";
import {shareLogsStore} from "@/libs/share-logs/+state/store";

@Component({
    components: {
        CrudTable
    }
})
export default class ProductNremShareLog extends Page {

    headers = [
        { text: 'Datum zápisu', align: 'left', sortable: false, value: 'createdAt', type: "datetime" },
        { text: 'Typ zápisu', align: 'left', sortable: false, value: 'type', type: "datetime" },
        { text: 'Ev. č.', align: 'left', sortable: false, value: 'share.evidenceNumber', type: "text" },
        { text: 'Původní vlastník', align: 'left', sortable: false, value: 'fromOwner', type: "text" },
        { text: 'Nový vlastník', align: 'left', sortable: false, value: 'toOwner', type: "text" },
        { text: 'Zadáno uživatelem', align: 'left', sortable: false, value: 'createdBy.displayName', type: "datetime" }
    ];

    clientId: string | null = null;
	fromId: number | null = null;
	toId: number | null = null;
    since: string | null = null;
    until: string | null = null;
    showFilters = false;
    productId = Constants.NremProductId;
    partyProductId = null;
    basicRules = [
        (v: any) => !!v || 'Toto pole je povinné.'
    ];

	pageIndex = 1;
	pageSize = 50;


	@Ref() crudTable!: CrudTable;

    @Watch("clientId")
    @Watch("fromId")
    @Watch("toId")
    @Watch("since")
    @Watch("until")
    filtersChanged(): void {
        this.loadData();
    }

	@Watch("pageIndex")
	@Watch("pageSize")
	paginationChanged() {
		this.loadData();
	}

    get items(): ShareLogModel[] {
		return [...shareLogsStore.useGetter(CrudGetter.Data)];
    }

	get totalRows (): number {
		return ({...shareLogsStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
	}

	get isMobile(): boolean {
		return window.outerWidth <= 1140;
	}

    mounted(): void {
        this.loadData();
    }

    loadData(): void {
        let filters: CrudQueryPredicate[] = [
            { field: "share.productId", op: "eq", comparand: Constants.NremProductId }
        ];
        filters = filters.concat(this.getFilters());
        shareLogsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ShareLogModel>([ { field: "createdAt", index: 1, order: "desc" }, { field: "evidenceNumber", index: 2, order: "asc" } ], filters, { pageIndex: this.pageIndex, pageSize: this.pageSize }));
    }

    getFilters(): CrudQueryPredicate[] {
		let filters: CrudQueryPredicate[] = [];
		if (this.clientId) {
			filters.push({
				field: 'id',
				op: 'wrap',
				comparand: [
					{ field: "fromOwner.partyId", op: "eq", comparand: this.clientId, junction: 'or' },
					{ field: "toOwner.partyId", op: "eq", comparand: this.clientId, junction: 'or' }
				],
				junction: 'and'
			});
		}
		if (this.since) {
			filters.push({ field: "createdAt", op: "gte", comparand: this.since });
		}
		if (this.until) {
			filters.push({ field: "createdAt", op: "lte", comparand: this.until });
		}
	    if (this.fromId && !isNaN(this.fromId)) {
		    filters.push({ field: "share.evidenceNumber", op: "gte", comparand: Number(this.fromId) });
	    }
	    if (this.toId && !isNaN(this.toId)) {
		    filters.push({ field: "share.evidenceNumber", op: "lte", comparand: Number(this.toId) });
	    }
      return filters;
    }

	download () {
		const payload = createCrudQueryPayload<ShareModel>([
			{ field: "createdAt", index: 1, order: "desc" },
			{ field: "evidenceNumber", index: 2, order: "asc" }
		], this.getFilters(), { pageIndex: this.pageIndex, pageSize: 9999999 });
		shareLogsStore.dispatch(CrudAction.Download, payload);
	}

}
