














import { CodebookModel, CodebookType, ProductTypeModel } from '@/libs/Api';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import { Guid } from '../functions/guid';

@Component({ })
export default class CodebookSelect extends Vue {

    @Prop({ default: "" }) label!: string;
    @VModel() innerValue!: string;
    @Prop() type!: string;
    @Prop({ default: null }) productId!: string | null;
    @Prop() readonly!: boolean;
    @Prop() rules!: [ any[] ];
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: true }) clearable!: boolean;

    protected items(): CodebookModel[] {
		const type = this.type;
		const codebookItems = codebooksStore.useGetter(CrudGetter.Data) as CodebookModel[];
        let items: CodebookModel[] = codebookItems?.filter((e: CodebookModel) => {
            return e.type == CodebookType[type as keyof typeof CodebookType];
        }).sort((a: CodebookModel, b: CodebookModel) => { return a.position! - b.position!; }).toArray();
		if (this.productId != null && this.productId != Guid.EmptyGuid()) {
			items = items.where(x => x.productId?.toLowerCase() == this.productId?.toLowerCase()).toArray();
		}
        if (this.innerValue == null && items.find((x: CodebookModel) => x.defaultValue)) {
            this.$emit("input", items.find((x: CodebookModel) => x.defaultValue).id);
        }
        return items;
    }

}
