import {RouteConfig} from 'vue-router';
import Account from './pages/Account.vue';

import AccountBase from '../account/pages/AccountBase.vue';
import AccountGeneral from '../account/pages/AccountGeneral.vue';
import AccountProducts from './pages/AccountProducts.vue';
import AccountProductNOIF from './pages/AccountProductNOIF.vue';
import AccountProductReOpportunities from './pages/AccountProductReOpportunities.vue';
import AccountProductNREI from './pages/AccountProductNREI.vue';
import AccountProductWealth from './pages/AccountProductWealth.vue';
import AccountProductClt from './pages/AccountProductClt.vue';
import AccountProductCft from './pages/AccountProductCft.vue';
import AccountProductNrem from './pages/AccountProductNrem.vue';
import ClientArticles from '../articles/pages/ClientArticles.vue';

export default [
    {
        path: '/account',
        name: 'Account',
        component: Account,
        meta: {
            clientZone: true
        },
        children: [
            {
                path: '',
                name: 'AccountBase',
                component: AccountBase,
                meta: {
                    clientZone: true
                },
            },
            {
                path: 'general',
                name: 'AccountGeneral',
                component: AccountGeneral,
                meta: {
                    clientZone: true
                },
            },
            {
                path: 'products',
                name: 'AccountProducts',
                component: AccountProducts,
                meta: {
                    clientZone: true
                },
            },
            {
                path: 'products/noif/:partyProductId',
                name: 'AccountProductNOIF',
                component: AccountProductNOIF,
                meta: {
                    clientZone: true
                },
                props: (route) => ({partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/re-opportunities/:partyProductId',
                name: 'AccountProductReOpportunities',
                component: AccountProductReOpportunities,
                meta: {
                    clientZone: true
                },
                props: (route) => ({partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/nrei/:partyProductId',
                name: 'AccountProductNREI',
                component: AccountProductNREI,
                meta: {
                    clientZone: true
                },
                props: (route) => ({partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/wealth/:partyProductId',
                name: 'AccountProductWealth',
                component: AccountProductWealth,
                meta: {
                    clientZone: true
                },
                props: (route) => ({partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/clt/:partyProductId',
                name: 'AccountProductClt',
                component: AccountProductClt,
                meta: {
                    clientZone: true
                },
                props: (route) => ({partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/cft/:partyProductId',
                name: 'AccountProductCft',
                component: AccountProductCft,
                meta: {
                    clientZone: true
                },
                props: (route) => ({partyProductId: route.params.partyProductId}),
            },
            {
                path: 'products/nrem/:partyProductId',
                name: 'AccountProductNrem',
                component: AccountProductNrem,
                meta: {
                    clientZone: true
                },
                props: (route) => ({partyProductId: route.params.partyProductId}),
            },
            {
                path: 'news',
                name: 'AccountNews',
                component: ClientArticles,
                meta: {
                    clientZone: true
                }
            },
        ]
    }
] as RouteConfig[];
