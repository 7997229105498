

































































































import { ApplicationUserModel, NotificationModel } from '@/libs/Api';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { notificationsStore } from '@/libs/notifications/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class Sidebar extends Vue {
    
    @Prop({ default: 0 }) notificationsLength!: number;
    applicationUser: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
    notificationSubscribtion: any = null;

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get notifications(): NotificationModel[] {
        return notificationsStore.useGetter(CrudGetter.Data, "sidebar");
    }

    routeChanged() {
        this.$emit("routeChanged");
    }

    mounted () {
        this.loadNotifications();
        const self = this;
        this.notificationSubscribtion = this.$store.subscribe((mutation, _state) => {
            if(mutation.type === notificationsStore.getActionName(CrudResponseAction.CreateSuccess)) {
                self.loadNotifications();
            }
            else if(mutation.type === notificationsStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                self.loadNotifications();
            }
            else if(mutation.type === notificationsStore.getActionName(CrudResponseAction.PartialUpdateSuccess)) {
                self.loadNotifications();
            }
            else if(mutation.type === notificationsStore.getActionName(CrudResponseAction.DeleteSuccess)) {
                self.loadNotifications();
            }
        });
    }

    loadNotifications () {
        notificationsStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<NotificationModel>([{ field: "time", index: 1, order: "desc" }], [
            { field: "ownerId", op: "eq", comparand: this.applicationUser.partyId! },
            { field: "time", op: "lte", comparand: moment().format("YYYY-MM-DDT23:59:59") },
            { field: "isConfirmed", op: "eq", comparand: false },
        ], undefined, false, "sidebar"));
    }

    destroyed () {
        if (this.notificationSubscribtion)
            this.notificationSubscribtion();
    }

}
