





































import { Component } from "vue-property-decorator";
import { articlesStore } from "@/libs/articles/+state/store";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";
import { CrudAction, CrudReponse } from "@/libs/core/+state/models/crud-action";
import { ArticleModel } from "@/libs/Api";
import Page from "@/Page.vue";

@Component({})
export default class ArticleCreateDialog extends Page {
    protected dialog = false;
    protected valid = true;

    protected articleName = "";

    protected basicRules = [(v: any) => !!v || "Toto pole je povinné."];

    protected get creating(): boolean {
        return articlesStore.useGetter(CrudGetter.Saving);
    }

    public open(): void {
        this.dialog = true;
        this.articleName = "";
    }

    private close(): void {
        this.dialog = false;
    }

    protected onCreateClick(): void {
        articlesStore.dispatch(CrudAction.Create, {
            item: {
                name: this.articleName,
                content: ""
            } as ArticleModel
        });
        this.subscribe(articlesStore, CrudReponse.Create).then((article: ArticleModel) => {
            this.close();
            this.$emit("created", article);
        });
    }
}
