import {registerCrudStore} from '@/libs/core/+state/register-crud-store';
import {FileActivityModel, FileModel, FolderModel} from '@/libs/Api';
import {BusinessModule} from '@/libs/core/models/business-module';
import { FilesDataService } from '../files-data-service';
import { FileActivitiesDataService } from '../file-activities-data-service';
import {FoldersDataService} from '@/libs/files/folders-data-service';

export const foldersStore = registerCrudStore<FolderModel, FolderModel>(BusinessModule.Folder, new FoldersDataService());
export const filesStore = registerCrudStore<FileModel, FileModel>(BusinessModule.File, new FilesDataService());
export const fileActivitiesStore = registerCrudStore<FileActivityModel, FileActivityModel>(BusinessModule.FileActivity, new FileActivitiesDataService());
