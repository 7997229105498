



















































import { ApplicationUserModel, PartyModel, ProductActivityModel, TransactionModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';

import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import ClientAddDialog from '@/libs/clients/components/ClientAddDialog.vue';
import { transactionsStore } from '@/libs/transactions/+state/store';
import CrudTable from '@/libs/common/components/CrudTable.vue';
import { Guid } from '@/libs/common/functions/guid';
import { productActivitiesStore } from '@/libs/products/+state/store';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import moment from 'moment';

@Component({
    components: {
        CrudTable
    }
})
export default class ProductActivities extends Vue {

    newComment: string | null = null;

    @Prop() id!: string;
    @Ref() crudTable!: CrudTable;

    get comments (): ProductActivityModel[] {
        return productActivitiesStore.useGetter(CrudGetter.Data, this.id);
    }

    get saving(): boolean {
        return productActivitiesStore.useGetter(CrudGetter.Saving, this.id);
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get today(): string {
        return moment().format("DD.MM.YYYY");
    }

    mounted () {
        productActivitiesStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<ProductActivityModel>([{ field: "createdAt", index: 0, order: "desc" }], [ { field: "productId", op: "eq", comparand: this.id } ], undefined, false, this.id));
    }

    createComment () {
        const newItem = {
            id: Guid.EmptyGuid(),
            description: this.newComment,
            productId: this.id,
            createdById: this.user.partyId
        } as ProductActivityModel;
        productActivitiesStore.dispatch(CrudAction.Create, { item: newItem, key: this.id });
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === productActivitiesStore.getActionName(CrudResponseAction.CreateSuccess)) {
                subscribtion();
                self.newComment = null;
            }
            else if (mutation.type === productActivitiesStore.getActionName(CrudResponseAction.CreateFailed)) {
                subscribtion();
            }
        });
    }

    deleteComment (item: ProductActivityModel) {
        productActivitiesStore.dispatch(CrudAction.Delete, createCrudQueryPayload(undefined, [{ field: "id", op: "eq", comparand: item.id! }], undefined, false, this.id));
    }

    textAreaToHtml (value : string, length = 0) {
        if (value) {
            value = value.replaceAll("\n", "<br />");
        }
        if (value) {
            if (length > 0) {
                if (value.length > length) {
                    value = value.substring(0, length).concat("...");
                }
            }
        }
        return value;
    }

}
