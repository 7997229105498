import {
    HttpResponse,
    ArticleActivityModel,
    RequestParams,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";

export class ArticleActivitiesDataService implements CrudService<ArticleActivityModel, ArticleActivityModel> {
    
    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw new Error("Method not implemented.");
    }
    
    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error("Method not implemented.");
    }
    
    getAll(query: CrudDataQuery<ArticleActivityModel>): Promise<CrudEnumerableResponse<ArticleActivityModel>> {
        return ApiService.api.getArticleActivityBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<ArticleActivityModel>> {
        return ApiService.api.getArticleActivityById(id, params);
    }

    create(item: ArticleActivityModel, params?: RequestParams): Promise<CrudResponse<ArticleActivityModel>> {
        return ApiService.api.insertArticleActivity(item, params);
    }

    update(item: ArticleActivityModel, params?: RequestParams): Promise<ArticleActivityModel> {
        return ApiService.api.updateArticleActivity(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<ArticleActivityModel> {
        return ApiService.api.patchArticleActivity(id, patches).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<ArticleActivityModel>): Promise<CrudEnumerableResponse<ArticleActivityModel>> {
        return ApiService.api.deleteArticleActivities(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    download(query: CrudDataQuery<ArticleActivityModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw "NotImplemented";
    }

}
