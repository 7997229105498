import {RouteConfig} from 'vue-router';

import SystemSettings from '@/libs/system-settings/pages/SystemSettings.vue';
import General from '@/libs/system-settings/pages/General.vue';
import Codebooks from '@/libs/system-settings/pages/Codebooks.vue';
import Accounts from '@/libs/system-settings/pages/Accounts.vue';

import Products from '@/libs/system-settings/pages/Products.vue';
import ProductNcif from '@/libs/system-settings/pages/ProductNcif.vue';
import ProductNoif from '@/libs/system-settings/pages/ProductNoif.vue';
import ProductReOpportunities from '@/libs/system-settings/pages/ProductReOpportunities.vue';
import ProductNrei from '@/libs/system-settings/pages/ProductNrei.vue';
import ProductWealth from '@/libs/system-settings/pages/ProductWealth.vue';
import ProductClt from '@/libs/system-settings/pages/ProductClt.vue';
import ProductCft from '@/libs/system-settings/pages/ProductCft.vue';
import ProductCredit from '@/libs/system-settings/pages/ProductCredit.vue';
import ProductNrem from '@/libs/system-settings/pages/ProductNrem.vue';

import ProductActivities from '@/libs/system-settings/components/ProductActivities.vue';
import ProductNotificationRules from '@/libs/system-settings/components/ProductNotificationRules.vue';
import ProductNoifTransactions from '@/libs/system-settings/components/ProductNoif/ProductNoifTransactions.vue';
import ProductNoifStateOfCapital from '@/libs/system-settings/components/ProductNoif/ProductNoifStateOfCapital.vue';
import ProductNoifStateOfCapitalYear from '@/libs/system-settings/components/ProductNoif/ProductNoifStateOfCapitalYear.vue';
import ProductNoifGeneral from '@/libs/system-settings/components/ProductNoif/ProductNoifGeneral.vue';
import ProductReOpportunitiesTransactions from '@/libs/system-settings/components/ProductReOpportunities/ProductReOpportunitiesTransactions.vue';
import ProductReOpportunitiesStateOfCapital from '@/libs/system-settings/components/ProductReOpportunities/ProductReOpportunitiesStateOfCapital.vue';
import ProductReOpportunitiesStateOfCapitalYear from '@/libs/system-settings/components/ProductReOpportunities/ProductReOpportunitiesStateOfCapitalYear.vue';
import ProductReOpportunitiesGeneral from '@/libs/system-settings/components/ProductReOpportunities/ProductReOpportunitiesGeneral.vue';

import ProductNreiTransactions from '@/libs/system-settings/components/ProductNrei/ProductNreiTransactions.vue';
import ProductNreiStateOfCapital from '@/libs/system-settings/components/ProductNrei/ProductNreiStateOfCapital.vue';
import ProductNreiStateOfCapitalYear from '@/libs/system-settings/components/ProductNrei/ProductNreiStateOfCapitalYear.vue';
import ProductNreiGeneral from '@/libs/system-settings/components/ProductNrei/ProductNreiGeneral.vue';
import ProductCreditSettings from '@/libs/system-settings/components/ProductCredit/ProductCreditSettings.vue';

import ProductWealthTransactions from '@/libs/system-settings/components/ProductWealth/ProductWealthTransactions.vue';
import ProductWealthExchangeRates from '@/libs/system-settings/components/ProductWealth/ProductWealthExchangeRates.vue';

import ProductCltGeneral from '@/libs/system-settings/components/ProductClt/ProductCltGeneral.vue';
import ProductCltTransactions from '@/libs/system-settings/components/ProductClt/ProductCltTransactions.vue';
import ProductCltTrades from '@/libs/system-settings/components/ProductClt/ProductCltTrades.vue';
import ProductCltExchangeRates from '@/libs/system-settings/components/ProductClt/ProductCltExchangeRates.vue';
import ProductCltRewards from '@/libs/system-settings/components/ProductClt/ProductCltRewards.vue';

import ProductCftGeneral from '@/libs/system-settings/components/ProductCft/ProductCftGeneral.vue';
import ProductCftTransactions from '@/libs/system-settings/components/ProductCft/ProductCftTransactions.vue';
import ProductCftRewards from '@/libs/system-settings/components/ProductCft/ProductCftRewards.vue';
import ProductCftTrades from '@/libs/system-settings/components/ProductCft/ProductCftTrades.vue';
import ProductCftExchangeRates from '@/libs/system-settings/components/ProductCft/ProductCftExchangeRates.vue';

import NremGeneral from '@/libs/system-settings/components/ProductNrem/ProductNremGeneral.vue';
import NremTransactions from '@/libs/system-settings/components/ProductNrem/ProductNremTransactions.vue';
import NremTrades from '@/libs/system-settings/components/ProductNrem/ProductNremTrades.vue';
import NremExchangeRates from '@/libs/system-settings/components/ProductNrem/ProductNremExchangeRates.vue';
import NremRewards from '@/libs/system-settings/components/ProductNrem/ProductNremRewards.vue';

import { Constants } from '../constants/constants';
import NremShares
    from "@/libs/system-settings/components/ProductNrem/ProductNremShares.vue";
import NremShareLog
    from "@/libs/system-settings/components/ProductNrem/ProductNremShareLog.vue";

import Articles from '@/libs/system-settings/pages/Articles.vue';
import ArticleList from '@/libs/system-settings/components/Articles/ArticleList.vue';
import ArticleReadings from '@/libs/system-settings/components/Articles/ArticleReadings.vue';

export default [
    {
        path: '/system-settings',
        name: 'SystemSettings',
        component: SystemSettings,
        meta: {
            reload: true,
        },
        children: [
            {
                path: '',
                name: 'SystemSettingsGeneral',
                component: General,
            },
            {
                path: 'codebooks',
                name: 'SystemSettingsCodebooks',
                component: Codebooks,
            },
            {
                path: 'products',
                name: 'SystemSettingsProducts',
                component: Products
            },
            {
                path: 'product-notification-rules',
                name: 'ProductNotificationRules',
                component: ProductNotificationRules
            },
            {
                path: "products/ncif",
                name: "SystemSettingsProductNcif",
                component: ProductNcif
            },
            {
                path: "products/noif",
                name: "SystemSettingsProductNoif",
                component: ProductNoif,
                children: [
                    {
                        path: "transactions",
                        name: "SystemSettingsProductNoifTransactions",
                        component: ProductNoifTransactions,
                        props: (route) => ({ id: Constants.NoifProductId }),
                    },
                    {
                        path: "comments",
                        name: "SystemSettingsProductNoifComments",
                        component: ProductActivities,
                        props: (route) => ({ id: Constants.NoifProductId }),
                    },
                    {
                        path: "state-of-capital",
                        name: "SystemSettingsProductNoifStateOfCapital",
                        component: ProductNoifStateOfCapital,
                        props: (route) => ({ id: Constants.NoifProductId }),
                    },
                    {
                        path: "state-of-capital-year",
                        name: "SystemSettingsProductNoifStateOfCapitalYear",
                        component: ProductNoifStateOfCapitalYear,
                        props: (route) => ({ id: Constants.NoifProductId }),
                    },
                    {
                        path: "general",
                        name: "SystemSettingsProductNoifGeneral",
                        component: ProductNoifGeneral,
                        props: (route) => ({ id: Constants.NoifProductId }),
                    }
                ]
            },
            {
                path: "products/re-opportunities",
                name: "SystemSettingsProductReOpportunities",
                component: ProductReOpportunities,
                children: [
                    {
                        path: "transactions",
                        name: "SystemSettingsProductReOpportunitiesTransactions",
                        component: ProductReOpportunitiesTransactions,
                        props: (route) => ({ id: Constants.ReOpportunitiesId }),
                    },
                    {
                        path: "comments",
                        name: "SystemSettingsProductReOpportunitiesComments",
                        component: ProductActivities,
                        props: (route) => ({ id: Constants.ReOpportunitiesId }),
                    },
                    {
                        path: "state-of-capital",
                        name: "SystemSettingsProductReOpportunitiesStateOfCapital",
                        component: ProductReOpportunitiesStateOfCapital,
                        props: (route) => ({ id: Constants.ReOpportunitiesId }),
                    },
                    {
                        path: "state-of-capital-year",
                        name: "SystemSettingsProductReOpportunitiesStateOfCapitalYear",
                        component: ProductReOpportunitiesStateOfCapitalYear,
                        props: (route) => ({ id: Constants.ReOpportunitiesId }),
                    },
                    {
                        path: "general",
                        name: "SystemSettingsProductReOpportunitiesGeneral",
                        component: ProductReOpportunitiesGeneral,
                        props: (route) => ({ id: Constants.ReOpportunitiesId }),
                    }
                ]
            },
            {
                path: "products/nrei",
                name: "SystemSettingsProductNrei",
                component: ProductNrei,
                children: [
                    {
                        path: "transactions",
                        name: "SystemSettingsProductNreiTransactions",
                        component: ProductNreiTransactions,
                        props: (route) => ({ id: Constants.NreiProductId }),
                    },
                    {
                        path: "comments",
                        name: "SystemSettingsProductNreiComments",
                        component: ProductActivities,
                        props: (route) => ({ id: Constants.NreiProductId }),
                    },
                    {
                        path: "state-of-capital",
                        name: "SystemSettingsProductNreiStateOfCapital",
                        component: ProductNreiStateOfCapital,
                        props: (route) => ({ id: Constants.NreiProductId }),
                    },
                    {
                        path: "state-of-capital-year",
                        name: "SystemSettingsProductNreiStateOfCapitalYear",
                        component: ProductNreiStateOfCapitalYear,
                        props: (route) => ({ id: Constants.NreiProductId }),
                    },
                    {
                        path: "general",
                        name: "SystemSettingsProductNreiGeneral",
                        component: ProductNreiGeneral,
                        props: (route) => ({ id: Constants.NreiProductId }),
                    }
                ]
            },
            {
                path: "products/wealth",
                name: "SystemSettingsProductWealth",
                component: ProductWealth,
                children: [
                    {
                        path: "transactions",
                        name: "SystemSettingsProductWealthTransactions",
                        component: ProductWealthTransactions,
                        props: (route) => ({ id: Constants.WealthProductId }),
                    },
                    {
                        path: "exchange-rates",
                        name: "SystemSettingsProductWealthExchangeRates",
                        component: ProductWealthExchangeRates,
                        props: (route) => ({ id: Constants.WealthProductId }),
                    },
                    {
                        path: "comments",
                        name: "SystemSettingsProductWealthComments",
                        component: ProductActivities,
                        props: (route) => ({ id: Constants.WealthProductId }),
                    },
                ]
            },
            {
                path: "products/clt",
                name: "SystemSettingsProductClt",
                component: ProductClt,
                children: [
                    {
                        path: "general",
                        name: "SystemSettingsProductCltGeneral",
                        component: ProductCltGeneral,
                        props: (route) => ({ id: Constants.CltProductId }),
                    },
                    {
                        path: "transactions",
                        name: "SystemSettingsProductCltTransactions",
                        component: ProductCltTransactions,
                        props: (route) => ({ id: Constants.CltProductId }),
                    },
                    {
                        path: "trades",
                        name: "SystemSettingsProductCltTrades",
                        component: ProductCltTrades,
                        props: (route) => ({ id: Constants.CltProductId }),
                    },
                    {
                        path: "exchange-rates",
                        name: "SystemSettingsProductCltExchangeRates",
                        component: ProductCltExchangeRates,
                        props: (route) => ({ id: Constants.CltProductId }),
                    },
                    {
                        path: "comments",
                        name: "SystemSettingsProductCltComments",
                        component: ProductActivities,
                        props: (route) => ({ id: Constants.CltProductId }),
                    },
                    {
                        path: "rewards",
                        name: "SystemSettingsProductCltRewards",
                        component: ProductCltRewards,
                        props: (route) => ({ id: Constants.CltProductId }),
                    },
                ]
            },
            {
                path: "products/cft",
                name: "SystemSettingsProductCft",
                component: ProductCft,
                children: [
                    {
                        path: "general",
                        name: "SystemSettingsProductCftGeneral",
                        component: ProductCftGeneral,
                        props: (route) => ({ id: Constants.CftProductId }),
                    },
                    {
                        path: "transactions",
                        name: "SystemSettingsProductCftTransactions",
                        component: ProductCftTransactions,
                        props: (route) => ({ id: Constants.CftProductId }),
                    },
                    {
                        path: "rewards",
                        name: "SystemSettingsProductCftRewards",
                        component: ProductCftRewards,
                        props: (route) => ({ id: Constants.CftProductId }),
                    },
                    {
                        path: "trades",
                        name: "SystemSettingsProductCftTrades",
                        component: ProductCftTrades,
                        props: (route) => ({ id: Constants.CftProductId }),
                    },
                    {
                        path: "exchange-rates",
                        name: "SystemSettingsProductCftExchangeRates",
                        component: ProductCftExchangeRates,
                        props: (route) => ({ id: Constants.CftProductId }),
                    },
                    {
                        path: "comments",
                        name: "SystemSettingsProductCftComments",
                        component: ProductActivities,
                        props: (route) => ({ id: Constants.CftProductId }),
                    },
                ]
            },
            {
                path: "products/nrem",
                name: "SystemSettingsNrem",
                component: ProductNrem,
                children: [
                    {
                        path: "general",
                        name: "SystemSettingsProductNremGeneral",
                        component: NremGeneral,
                        props: (route) => ({ id: Constants.NremProductId }),
                    },
                    {
                        path: "transactions",
                        name: "SystemSettingsProductNremTransactions",
                        component: NremTransactions,
                        props: (route) => ({ id: Constants.NremProductId }),
                    },
                    {
                        path: "trades",
                        name: "SystemSettingsProductNremTrades",
                        component: NremTrades,
                        props: (route) => ({ id: Constants.NremProductId }),
                    },
                    {
                        path: "exchange-rates",
                        name: "SystemSettingsProductNremExchangeRates",
                        component: NremExchangeRates,
                        props: (route) => ({ id: Constants.NremProductId }),
                    },
                    {
                        path: "comments",
                        name: "SystemSettingsProductNremComments",
                        component: ProductActivities,
                        props: (route) => ({ id: Constants.NremProductId }),
                    },
                    {
                        path: "rewards",
                        name: "SystemSettingsProductNremRewards",
                        component: NremRewards,
                        props: (route) => ({ id: Constants.NremProductId }),
                    },
                    {
                        path: "shares",
                        name: "SystemSettingsProductNremShares",
                        component: NremShares,
                        props: (route) => ({ id: Constants.NremProductId }),
                    },
                    {
                        path: "share-log",
                        name: "SystemSettingsProductNremShareLog",
                        component: NremShareLog,
                        props: (route) => ({ id: Constants.NremProductId }),
                    },
                ]
            },
            {
                path: "products/credit",
                name: "SystemSettingsProductCredit",
                component: ProductCredit,
                children: [
                    {
                        path: "transactions",
                        name: "SystemSettingsProductCreditSettings",
                        component: ProductCreditSettings,
                        props: (route) => ({ id: Constants.CreditProductId }),
                    }
                ]
            },
            {
                path: "news",
                name: "SystemSettingsArticles",
                component: Articles,
                children: [
                    {
                        path: "list",
                        name: "SystemSettingsArticleList",
                        component: ArticleList
                    },
                    {
                        path: "readings",
                        name: "SystemSettingsArticleReadings",
                        component: ArticleReadings
                    }
                ]
            },
            {
                path: 'accounts',
                name: 'SystemSettingsAccounts',
                component: Accounts,
            }
        ]
    },
] as RouteConfig[];
