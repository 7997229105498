var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body"},[(!_vm.loading)?_c('div',{class:_vm.$route.name == 'SignIn' || _vm.$route.name == 'SignUp' || _vm.$route.name == 'PasswordRecovery'
                ? 'full-width'
                : '',attrs:{"id":"app","data-app":""}},[(_vm.isLoggedIn)?_c('div',{staticClass:"d-flex",attrs:{"id":"nav"}},[_c('div',{staticClass:"app-logo d-flex"},[_c('router-link',{staticClass:"app-name",attrs:{"to":{ name: 'Dashboard' }}},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"alt":"Nordelo"}}),_c('span',{staticClass:"application-name"},[_vm._v("Nordelo.app")])])],1),_c('div',{staticClass:"search pl-5 flex-grow-1"},[(_vm.accountType != 'Client')?_c('SearchBox',{on:{"changed":function($event){_vm.showMobileNav = false}}}):_vm._e()],1),_c('div',{staticClass:"right-menu flex-shrink-1 d-flex flex-row"},[(_vm.accountType != 'Client')?_c('div',{staticClass:"d-flex flex-row align-items-center flex-grow-1 px-2"},[_c('NotificationDialog',{ref:"dialog",attrs:{"activator":"Vytvořit upozornění","activator-icon-right":"mdi-flash-outline"}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-row align-items-center flex-grow-1 px-2"},[(_vm.userInfo)?_c('v-btn',{staticClass:"user-button",attrs:{"to":{ name: 'UserSettingsGeneral' }}},[(_vm.userInfo.avatar)?_c('span',{staticClass:"avatar-image",style:("background-image: url('https://nordeloproductionstorage.blob.core.windows.net/nordelo-public/Avatars/" +
                                    _vm.userInfo.avatar +
                                    "')")}):_c('v-icon',[_vm._v("mdi-account-outline")]),_vm._v(" "+_vm._s(_vm.userInfo.firstName)+" "+_vm._s(_vm.userInfo.lastName)+" ")],1):_vm._e()],1),_c('div',{staticClass:"d-flex flex-row align-items-center flex-grow-1 px-2"},[_c('v-btn',{staticClass:"signout-button",attrs:{"icon":""},on:{"click":_vm.signout}},[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1)],1)]),_c('div',{staticClass:"burger"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleNavbar()}}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1),_c('div',{class:_vm.showMobileNav ? 'show' : '',attrs:{"id":"mobile-nav"}},[_c('div',{staticClass:"d-flex align-items-center px-4 pb-2 pt-4"},[_c('div',{staticClass:"flex-grow-1"},[(_vm.userInfo)?_c('v-btn',{staticClass:"user-button",attrs:{"to":{ name: 'UserSettingsGeneral' }},on:{"click":function($event){return _vm.toggleNavbar()}}},[(_vm.userInfo.avatar)?_c('span',{staticClass:"avatar-image",style:("background-image: url('https://nordeloproductionstorage.blob.core.windows.net/nordelo-public/Avatars/" +
                                        _vm.userInfo.avatar +
                                        "')")}):_c('v-icon',[_vm._v("mdi-account-outline")]),_vm._v(" "+_vm._s(_vm.userInfo.firstName)+" "+_vm._s(_vm.userInfo.lastName)+" ")],1):_vm._e()],1),_c('div',{staticClass:"flex-shrink-1 pl-4"},[_c('v-btn',{staticClass:"signout-button",attrs:{"icon":""},on:{"click":function($event){_vm.signout();
                                _vm.showMobileNav = false;}}},[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1)],1)]),_c('div',{staticClass:"px-4 pt-1 pb-2"},[_c('SearchBox',{on:{"changed":function($event){_vm.showMobileNav = false}}})],1),(_vm.isLoggedIn)?_c('Sidebar',{attrs:{"notificationsLength":_vm.notifications.length},on:{"routeChanged":function($event){_vm.showMobileNav = false}}}):_vm._e(),_c('div',{staticClass:"d-flex px-4"},[_c('div',{staticClass:"flex-grow-1 pr-4"},[_c('NotificationDialog',{ref:"dialog",attrs:{"activator":"Nové upozornění","activator-icon-right":"mdi-flash-outline"}})],1),_c('div',{staticClass:"flex-shrink-1"},[_c('div',{staticClass:"burger"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleNavbar()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])])],1)]):_vm._e(),(!_vm.loadingUserData)?_c('div',{class:!_vm.isLoggedIn ? 'full-height' : '',attrs:{"id":"wrapper"}},[(_vm.accountType != 'Client' && _vm.isLoggedIn && !_vm.loadingUserData)?_c('Sidebar',{attrs:{"notificationsLength":_vm.notifications.length}}):_vm._e(),(!_vm.loadingUserData)?_c('router-view'):_vm._e()],1):_vm._e(),_c('client-article-detail-dialog',{ref:"articleDetailDialog"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }