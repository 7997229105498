































import {Component, Prop, Vue} from 'vue-property-decorator';

import {CrudAction} from '@/libs/core/+state/models/crud-action';
import {CrudGetter} from '@/libs/core/+state/models/crud-getter';
import {createCrudQueryPayload} from '@/libs/core/+state/models/crud-query-payload';
import { codebooksStore } from '@/libs/codebooks/+state/store';
import { CodebookModel } from '@/libs/Api';

@Component({ })
export default class SystemSettingsCodebooks extends Vue {

  firstLoad = true;

  get pending(): boolean {
    const pending = codebooksStore.useGetter(CrudGetter.Pending);
    if (!pending) {
      this.firstLoad = false;
    }
    return pending;
  }

  get codebooks(): any {
    return codebooksStore.useGetter(CrudGetter.Data);
  }

  mounted() {
    this.getCodebooks();
  }

  getCodebooks() {
    codebooksStore.dispatch(CrudAction.GetAll, createCrudQueryPayload<CodebookModel>({
      field: 'name',
      order: 'asc'
    }));
  }

}
