





















































































































































































































































































































import {ApplicationUserModel, ArticleModel, PartyModel, PartyTypeEnum} from '@/libs/Api';
import { Component, Ref } from "vue-property-decorator";
import { partiesStore } from "@/libs/parties/+state/store";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";
import MaritalStatusSelect from "@/libs/common/components-smart/MaritalStatusSelect.vue";
import { applicationUserStore } from "@/libs/user-settings/+state/store";
import moment from "moment";
import { articlesStore } from "@/libs/articles/+state/store";
import { CrudAction, CrudReponse } from "@/libs/core/+state/models/crud-action";
import { createCrudQueryPayload } from "@/libs/core/+state/models/crud-query-payload";
import Page from "@/Page.vue";
import ApiService from "@/libs/core/api-service";
import ArticleDetailDialog from "@/libs/articles/pages/ClientArticleDetailDialog.vue";
import ClientArticleDetailDialog from "@/libs/articles/pages/ClientArticleDetailDialog.vue";

@Component({
	computed: {
		PartyTypeEnum() {
			return PartyTypeEnum
		}
	},
    components: {
        ClientArticleDetailDialog,
        ArticleDetailDialog,
        MaritalStatusSelect
    }
})
export default class AccountGeneral extends Page {
    id = (applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel).partyId!;
    valid = false;
    basicRules = [];

    @Ref() articleDetailDialog: ClientArticleDetailDialog;

    get client(): PartyModel {
        return partiesStore.useGetter(CrudGetter.Detail, this.id);
    }

    mounted(): void {
        const applicationUser = applicationUserStore.useGetter(CrudGetter.Detail) as ApplicationUserModel;
        const lastArticleCheckAt =
            applicationUser.lastArticleCheckAt != null
                ? moment(applicationUser.lastArticleCheckAt).toDate()
                : moment("2022-01-01").toDate();
        articlesStore.dispatch(
            CrudAction.GetAll,
            createCrudQueryPayload<ArticleModel>(
                { field: "publishedAt", index: 1, order: "desc" },
                [{ field: "isPublished", op: "eq", comparand: true }],
                { pageIndex: 1, pageSize: 1 }
            )
        );
        this.subscribe(articlesStore, CrudReponse.GetAll).then((articles: ArticleModel[]) => {
            if (articles.any()) {
                const createdAt = moment(articles.first().createdAt).toDate();
                if (createdAt > lastArticleCheckAt) {
                    this.articleDetailDialog.open(articles.first().id);
                }
            }
            ApiService.api.updateArticleCheck();
        });
    }
}
