

















































































































import { ApplicationUserModel, ArticleModel, PartyCreditModel } from "@/libs/Api";
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import { CrudAction } from "@/libs/core/+state/models/crud-action";
import { createCrudQueryPayload } from "@/libs/core/+state/models/crud-query-payload";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";
import { CrudQueryPredicate } from "@/libs/core/+state/models/crud-query-predicate";
import CrudTable from "@/libs/common/components/CrudTable.vue";
import { applicationUserStore } from "@/libs/user-settings/+state/store";
import { articlesStore } from "@/libs/articles/+state/store";
import ClientArticleDetailDialog from "@/libs/articles/pages/ClientArticleDetailDialog.vue";
import ApiService from "@/libs/core/api-service";
import moment from "moment";

@Component({
    components: {
        ClientArticleDetailDialog,
        CrudTable
    }
})
export default class Credits extends Vue {
	filterTypeId: string | null = null;
    authorId: string | null = null;
    since: string | null = null;
    until: string | null = null;
    direction: string | null = null;
	filterThisMonth = false;
    showFilters = false;
    user: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail);
    authors: string[] = [];

    @Ref() crudTable!: CrudTable;
    @Ref() articleDetailDialog!: ClientArticleDetailDialog;

    @Watch("authorId")
    @Watch("filterTypeId")
    @Watch("since")
    @Watch("until")
    @Watch("direction")
    @Watch("filterThisMonth")
    onFilterChange(): void {
        this.onLoadItems();
    }

    get items(): ArticleModel[] {
        return articlesStore.useGetter(CrudGetter.Data);
    }

	get isClientZone(): boolean {
		return this.$route.meta?.clientZone == true;
	}

    mounted(): void {
        this.onLoadAuthors().then(() => {
            this.onLoadItems();
        });
    }

    onLoadItems(): void {
        let filters: CrudQueryPredicate[] = [
	        {
				field: "isPublished",
		        op: "eq",
		        comparand: true
	        }
        ];
        if (this.filterTypeId) {
            filters.push({ field: "articleTypeId", op: "eq", comparand: this.filterTypeId });
        }
        if (this.authorId) {
            filters.push({ field: "createdById", op: "eq", comparand: this.authorId });
        }
        if (this.since) {
            filters.push({
                field: "publishedAt",
                op: "gte",
                comparand: moment(this.since)
                    .startOf("day")
                    .format("YYYY-MM-DDTHH:mm:ss")
            });
        }
        if (this.until) {
            filters.push({
                field: "publishedAt",
                op: "lte",
                comparand: moment(this.until)
                    .endOf("day")
                    .format("YYYY-MM-DDTHH:mm:ss")
            });
        }
		if (this.filterThisMonth) {
			filters.push({
				field: "publishedAt",
				op: "gte",
				comparand: moment()
					.startOf("month")
					.format("YYYY-MM-DDTHH:mm:ss")
			});
			filters.push({
				field: "publishedAt",
				op: "lte",
				comparand: moment()
					.endOf("month")
					.format("YYYY-MM-DDTHH:mm:ss")
			});
		}
        if (this.direction) {
            filters.push({ field: "direction", op: "eq", comparand: this.direction });
        }
        articlesStore.dispatch(
            CrudAction.GetAll,
            createCrudQueryPayload<ArticleModel>([{ field: "publishedAt", index: 1, order: "desc" }], filters)
        );
    }

    onArticleDetailClick(id: string): void {
        this.articleDetailDialog.open(id);
    }

    onLoadAuthors(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            ApiService.api
                .getArticleGroupped({
                    groupby: [
                        {
                            field: "createdById"
                        }
                    ],
                    projections: [
                        {
                            field: "createdById",
                            outputField: "createdById"
                        }
                    ]
                })
                .then(e => {
                    this.authors = e.data.returnValue.select(n => n.key.createdById).toArray();
                    resolve(this.authors);
                })
                .catch(e => reject(e));
        });
    }
}
