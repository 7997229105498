







































































import { Component } from "vue-property-decorator";
import { articleActivitiesStore, articlesStore } from "@/libs/articles/+state/store";
import { CrudAction, CrudReponse } from "@/libs/core/+state/models/crud-action";
import { ArticleModel, QueryOperator } from "@/libs/Api";
import Page from "@/Page.vue";
import ArticleComments from "@/libs/system-settings/components/Articles/ArticleComments.vue";

import CloudFilesFlat from "@/libs/cloud/components/CloudFilesFlat.vue";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";
import ApiService from "@/libs/core/api-service";
import { Guid } from "@/libs/common/functions/guid";

@Component({
    components: {
        CloudFilesFlat,
        ArticleComments
    }
})
export default class ClientArticleDetailDialog extends Page {
    public dialog = false;
    protected valid = true;

    protected article: ArticleModel = null;
    protected isLoading = false;

    private readingTimeout = null;

    get hasComments(): boolean {
        return articleActivitiesStore.useGetter(CrudGetter.Data, "ArticleComments")?.any() == true;
    }

    public open(id: string): void {
        this.dialog = true;
        this.isLoading = true;
        articlesStore.dispatch(CrudAction.Get, { id });
        this.subscribe(articlesStore, CrudReponse.Get).then((article: ArticleModel) => {
            this.article = { ...article };
            this.isLoading = false;
            if (this.$route.meta?.clientZone == true) {
				clearTimeout(this.readingTimeout);
                this.readingTimeout = setTimeout(() => {
                    if (this.dialog) {
                        this.setArticleReading();
                    }
                }, 5000);
            }
        });
    }

    public close(): void {
	    clearTimeout(this.readingTimeout);
        this.dialog = false;
    }

    private setArticleReading(): void {
        ApiService.api
            .getArticleReadingBatch(1, 1, {
                predicates: [{ field: "articleId", op: QueryOperator.Eq, comparand: this.article.id }]
            })
            .then((e: any) => {
                debugger;
                if (!e.data.returnValue.items.any()) {
                    ApiService.api.insertArticleReading({
                        articleId: this.article.id,
                        partyId: Guid.EmptyGuid()
                    });
                }
            });
    }
}
