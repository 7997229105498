




























































































































import { ApplicationUserModel, ArticleActivityModel } from "@/libs/Api";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CrudAction, CrudResponseAction } from "@/libs/core/+state/models/crud-action";
import { createCrudQueryPayload } from "@/libs/core/+state/models/crud-query-payload";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";
import moment from "moment";
import { applicationUserStore } from "@/libs/user-settings/+state/store";
import { Guid } from "@/libs/common/functions/guid";

import { cloneDeep } from "lodash";
import { articleActivitiesStore } from "@/libs/articles/+state/store";

@Component
export default class ClientComments extends Vue {
    newComment: string | null = null;
    commentsList: ArticleActivityModel[] = [];
    itemToEdit: string | null = null;
    creating = false;
    saving: string | null = Guid.EmptyGuid();
    deleting: string | null = Guid.EmptyGuid();

    get today(): string {
        return moment().format("DD.MM.YYYY");
    }

    get comments(): ArticleActivityModel[] {
        return articleActivitiesStore.useGetter(CrudGetter.Data, 'ArticleComments') ?? [];
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    @Prop() articleId!: string;
    @Prop() isReadonly!: boolean;

    @Watch("comments")
    commentsChanged(v: ArticleActivityModel[]): void {
        this.commentsList = cloneDeep(v);
    }

    @Watch("id")
    onIdChange(): void {
        this.loadComments();
    }

    mounted(): void {
        this.loadComments();
    }

    loadComments(): Promise<number> {
		return new Promise<number>((resolve, reject) => {
			articleActivitiesStore.dispatch(
				CrudAction.GetAll,
				createCrudQueryPayload<ArticleActivityModel>(
					[{ field: "createdAt", index: 0, order: "desc" }],
					[{ field: "articleId", op: "eq", comparand: this.articleId }],
					undefined,
					false,
					'ArticleComments'
				)
			);
			const subscribtion = this.$store.subscribe((mutation, _state) => {
				if (mutation.type === articleActivitiesStore.getActionName(CrudResponseAction.GetAllSuccess)) {
					subscribtion();
					resolve(mutation.payload.data.length);
				} else if (mutation.type === articleActivitiesStore.getActionName(CrudResponseAction.GetAllFailed)) {
					subscribtion();
					reject();
				}
			});
		});
    }

    createComment(): void {
        const newItem = {
            id: Guid.EmptyGuid(),
            description: this.newComment,
            articleId: this.articleId,
            createdById: this.user.partyId
        } as ArticleActivityModel;
        articleActivitiesStore.dispatch(CrudAction.Create, { item: newItem, key: 'ArticleComments' });
        this.creating = true;
        const self = this;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === articleActivitiesStore.getActionName(CrudResponseAction.CreateSuccess)) {
                subscribtion();
                self.newComment = null;
	            self.creating = false;
				self.loadComments().then((e) => {
					self.$emit("commentCountChange", e);
				});
            } else if (mutation.type === articleActivitiesStore.getActionName(CrudResponseAction.CreateFailed)) {
                subscribtion();
	            self.creating = false;
            }
        });
    }

    deleteComment(item: ArticleActivityModel): void {
        articleActivitiesStore.dispatch(
            CrudAction.Delete,
            createCrudQueryPayload(
                undefined,
                [{ field: "id", op: "eq", comparand: item.id! }],
                undefined,
                false,
	            'ArticleComments'
            )
        );
        const self = this;
        this.deleting = item!.id!;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === articleActivitiesStore.getActionName(CrudResponseAction.DeleteSuccess)) {
                subscribtion();
                self.deleting = null;
	            self.loadComments().then((e) => {
					self.$emit("commentCountChange", e);
	            });
            } else if (mutation.type === articleActivitiesStore.getActionName(CrudResponseAction.DeleteFailed)) {
                subscribtion();
                console.log("Comment update failed");
                self.deleting = null;
            }
        });
    }

    editComment(item: ArticleActivityModel): void {
        this.itemToEdit = item.id!;
    }

    saveComment(): void {
        if (this.itemToEdit != null) {
            this.saving = this.itemToEdit;
            const item = this.commentsList.find(x => x.id == this.itemToEdit);
            articleActivitiesStore.dispatch(CrudAction.Update, { item: item, key: 'ArticleComments' });
            const self = this;
            this.saving = item!.id!;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === articleActivitiesStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                    subscribtion();
                    self.itemToEdit = null;
                    this.saving = null;
                } else if (mutation.type === articleActivitiesStore.getActionName(CrudResponseAction.UpdateFailed)) {
                    subscribtion();
                    console.log("Comment update failed");
                    this.saving = null;
                }
            });
        }
    }

    textAreaToHtml(value: string, length = 0): string {
        if (value) {
            value = value.replaceAll("\n", "<br />");
        }
        if (value) {
            if (length > 0) {
                if (value.length > length) {
                    value = value.substring(0, length).concat("...");
                }
            }
        }
        return value;
    }
}
