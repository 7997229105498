















































import { ArticleReadingModel } from "@/libs/Api";
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import { CrudAction } from "@/libs/core/+state/models/crud-action";
import { createCrudQueryPayload } from "@/libs/core/+state/models/crud-query-payload";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";

import CrudTable from "@/libs/common/components/CrudTable.vue";
import { articleReadingsStore } from "@/libs/articles/+state/store";
import ArticleSelect from "@/libs/common/components-smart/ArticleSelect.vue";
import {CrudQueryPredicate} from '@/libs/core/+state/models/crud-query-predicate';

@Component({
    components: {
        ArticleSelect,
        CrudTable
    }
})
export default class ArticleReadings extends Vue {
    headers = [
        { text: "Článek", align: "left", sortable: false, value: "article.name", type: "text", required: true },
        { text: "Uživatel", align: "left", sortable: false, value: "party.displayName", type: "text", required: true },
        {
            text: "Datum a čas přečtení",
            align: "left",
            sortable: false,
            value: "createdAt",
            required: true,
            type: "datetime"
        }
    ];
	showFilters = false;
	articleId = null;

    @Ref() crudTable!: CrudTable;

	@Watch("articleId")
	onFilterChange(): void {
		this.onLoadItems();
	}

    get items(): ArticleReadingModel[] {
        return articleReadingsStore.useGetter(CrudGetter.Data);
    }

    get pending(): boolean {
        return articleReadingsStore.useGetter(CrudGetter.Pending);
    }

    mounted(): void {
        this.onLoadItems();
    }

    onLoadItems(): void {
	    const filters: CrudQueryPredicate[] = [];
	    if (this.articleId) {
		    filters.push({ field: "articleId", op: "eq", comparand: this.articleId });
	    }
        articleReadingsStore.dispatch(
            CrudAction.GetAll,
            createCrudQueryPayload<ArticleReadingModel>([{ field: "createdAt", index: 1, order: "desc" }], filters)
        );
    }
}
