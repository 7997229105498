















































import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { getAccessToken } from "axios-jwt";
import axios from "axios";
import { FileModel, FolderModel } from "@/libs/Api";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";
import { filesStore, foldersStore } from "@/libs/files/+state/store";
import { CrudAction, CrudReponse } from "@/libs/core/+state/models/crud-action";
import { createCrudQueryPayload } from "@/libs/core/+state/models/crud-query-payload";
import Page from "@/Page.vue";

const pause = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

@Component({})
export default class CloudFilesFlat extends Page {
    @Prop() entityId!: string;
    @Prop() isReadonly!: string;

    private folderId: string | null = null;
    protected isUploading = false;

    @Ref() fileInput: HTMLInputElement;

    @Watch("folder")
    onFolderChange(): void {
        this.onLoadItems();
    }

    get files(): FileModel[] {
        return filesStore.useGetter(CrudGetter.Data, "Files") ?? [];
    }

    mounted(): void {
        this.onLoadItems();
    }

    onLoadItems(): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            if (this.entityId) {
                foldersStore.dispatch(
                    CrudAction.GetAll,
                    createCrudQueryPayload<FolderModel>(
                        undefined,
                        [{ field: "entityId", op: "eq", comparand: this.entityId }],
                        undefined,
                        false,
                        "Files"
                    )
                );
                this.subscribe(foldersStore, CrudReponse.GetAll, "Files").then((folders: FolderModel[]) => {
                    if (folders?.any()) {
                        this.folderId = folders.first().id;
                        filesStore.dispatch(
                            CrudAction.GetAll,
                            createCrudQueryPayload<FileModel>(
                                [{ field: "fileName", index: 0, order: "asc" }],
                                [{ field: "folderId", op: "eq", comparand: this.folderId }],
                                undefined,
                                false,
                                "Files"
                            )
                        );
                        this.subscribe(filesStore, CrudReponse.GetAll, 'Files').then(files => {
							resolve(files.length);
                        });
                    }
                }).catch(e => reject(e));
            } else {
                reject();
            }
        });
    }

    onUploadClick(): void {
        this.fileInput.click();
    }

    uploadFile(event: any): void {
        const file = event.target.files[0];
        let data: FormData = new FormData();
        data.append("path", this.folderId);
        data.append("action", "save");
        data.append(
            "data",
            JSON.stringify({
                path: null,
                action: null,
                name: file.name,
                isFile: true,
                id: `${this.folderId}`,
                filterPath: `${this.folderId}//`,
                filterId: `${this.folderId}`,
                targetPath: `${this.folderId}`
            })
        );
        data.append("uploadFiles", file);
        const baseUrl = (window as any).ApiService.baseUrl;
        const token = getAccessToken();
        this.isUploading = true;
        axios({
            url: baseUrl + "/api/files/upload",
            method: "POST",
            responseType: "json", // important
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Folder: this.folderId,
                Authorization: "Bearer " + token
            },
            data: data
        })
            .then(() => {
                this.onLoadItems().then((fileCount: number) => {
					this.$emit("fileCountChanged", fileCount);
                });
            })
            .finally(() => {
                this.isUploading = false;
            });
        this.fileInput.value = "";
    }

    onDownloadFileClick(fileDetail: FileModel): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let fdata: FormData = new FormData();
            fdata.append(
                "downloadInput",
                JSON.stringify({
                    action: "download",
                    data: [{ isFile: true, id: fileDetail.id }],
                    names: [fileDetail.id]
                    // path: this.path
                })
            );

            const baseUrl = (window as any).ApiService.baseUrl;
            const token = getAccessToken();

            axios({
                url: baseUrl + "/api/files/download",
                method: "POST",
                responseType: "blob", // important
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + token
                },
                data: fdata
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileDetail.fileName);
                    document.body.appendChild(link);
                    link.click();
                    resolve(true);
                })
                .catch(ex => {
                    debugger;
                    // this.downloadingErrorDialog = true;
                    reject(ex);
                });
        });
    }

    onDeleteFileClick(file: FileModel): void {
        filesStore.dispatch(CrudAction.Delete, { id: file.id });
        this.subscribe(filesStore, CrudReponse.Delete).then(() => {
            this.onLoadItems().then((fileCount: number) => {
	            this.$emit("fileCountChanged", fileCount);
            });
        });
    }
}
