import {registerCrudStore} from '@/libs/core/+state/register-crud-store';
import {ArticleModel, ArticleReadingModel, ArticleActivityModel} from '@/libs/Api';
import {ArticlesDataService} from '@/libs/articles/articles-data-service';
import {ArticleReadingsDataService} from '@/libs/articles/article-readings-data-service';
import {ArticleActivitiesDataService} from '@/libs/articles/article-activities-data-service';
import {BusinessModule} from '@/libs/core/models/business-module';

export const articlesStore = registerCrudStore<ArticleModel, ArticleModel>(BusinessModule.Article, new ArticlesDataService());
export const articleReadingsStore = registerCrudStore<ArticleReadingModel, ArticleReadingModel>(BusinessModule.ArticleReading, new ArticleReadingsDataService());
export const articleActivitiesStore = registerCrudStore<ArticleActivityModel, ArticleActivityModel>(BusinessModule.ArticleActivity, new ArticleActivitiesDataService());
