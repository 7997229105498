import {RouteConfig} from 'vue-router';
import ClientArticles from './pages/ClientArticles.vue';

export default [
    {
        path: '/news',
        name: 'ClientArticles',
        component: ClientArticles
    }
] as RouteConfig[];
