

































































































































































































































































































































































































































import ApiService from "@/libs/core/api-service";
import {
    AccountTypeEnum,
    ApplicationUserModel,
    PartyModel,
    PartyTypeEnum,
    ResetPasswordModel,
    SendPartyRegistrationModel
} from "@/libs/Api";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { partiesStore } from "@/libs/parties/+state/store";
import { CrudAction, CrudResponseAction } from "@/libs/core/+state/models/crud-action";
import { createCrudQueryPayload } from "@/libs/core/+state/models/crud-query-payload";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";
import MaritalStatusSelect from "@/libs/common/components-smart/MaritalStatusSelect.vue";

import { authStore } from "@/libs/auth/+store/store";
import { AuthAction } from "@/libs/auth/models/auth-state";
import { applicationUserStore } from "@/libs/user-settings/+state/store";
import { Guid } from "@/libs/common/functions/guid";

@Component({
    computed: {
        PartyTypeEnum() {
            return PartyTypeEnum;
        }
    },
    components: {
        MaritalStatusSelect
    }
})
export default class ClientGeneral extends Vue {
    valid = false;
    basicRules = [(v: any) => !!v || "Toto pole je povinné."];
    showToastSuccess = false;
    showToastError = false;
    pendingPasswordRecovery = false;
    sendingPartyRegistration = false;
    canSendRegistrationEmail = false;
    deleteClientDialog = false;
    archiveClientDialog = false;
    deletingUser = false;
    archivingUser = false;
    emailExists = false;
    emailOrNullRules = [
        (v: any) => {
            if (!v) {
                return true;
            } else {
                //eslint-disable-next-line
                var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (regex.test(v)) {
                    return true;
                } else {
                    return "Zadejte platný e-mail";
                }
            }
        }
    ];
    user: ApplicationUserModel = applicationUserStore.useGetter(CrudGetter.Detail);

    headersChildren = [
        { text: "Jméno", align: "left", sortable: false, value: "firstName", type: "text", required: false },
        { text: "Příjmení", align: "left", sortable: false, value: "lastName", type: "text", required: false },
        {
            text: "Rodné číslo",
            align: "left",
            sortable: false,
            value: "personalIdentificationNumber",
            type: "text",
            required: false
        },
        { text: "Datum narození", align: "left", sortable: false, value: "birthdate", type: "date", required: false }
    ];

    get client(): PartyModel {
        const client = partiesStore.useGetter(CrudGetter.Detail, this.id);
        this.canSendRegistrationEmail = client?.email != null && client?.email?.length > 0;
        return client;
    }

    get saving(): boolean {
        return partiesStore.useGetter(CrudGetter.Saving, this.id);
    }

    get canEditClientCode() {
        return this.user.accountType == AccountTypeEnum.Admin || this.user.accountType == AccountTypeEnum.Manager;
    }

    @Prop() id!: string;
    @Ref() form!: any;

    save() {
        if (this.form.validate()) {
            const self = this;
            if (this.client!.email != null && this.client!.email.length > 0) {
                partiesStore.dispatch(
                    CrudAction.GetAll,
                    createCrudQueryPayload<PartyModel>(
                        undefined,
                        [
                            { field: "email", op: "eq", comparand: this.client!.email },
                            { field: "id", op: "ne", comparand: this.client!.id }
                        ],
                        undefined,
                        false,
                        "VerifyExistingEmail"
                    )
                );
                const subscribtion = this.$store.subscribe((mutation, _state) => {
                    if (mutation.type === partiesStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                        subscribtion();
                        if (mutation.payload.data.length > 0) {
                            this.emailExists = true;
                        } else {
                            self.updateClient();
                        }
                    } else if (mutation.type === partiesStore.getActionName(CrudResponseAction.GetAllNoop)) {
                        subscribtion();
                    }
                });
            } else {
                this.updateClient();
            }
        }
    }

    updateClient() {
        partiesStore.dispatch(CrudAction.Update, { item: this.client, key: this.id });
    }

    recoverPassword() {
        this.pendingPasswordRecovery = true;

        setTimeout(() => {
            const resetPasswordModel: ResetPasswordModel = {
                email: this.client.email!
            };
            authStore.dispatch(AuthAction.ResetPassword, resetPasswordModel);
            const self = this;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === authStore.getActionName(AuthAction.ResetPasswordResponse)) {
                    debugger;
                    if (mutation.payload.succeeded) {
                        self.showToastSuccess = true;
                    } else if (mutation.payload.errors.length > 0) {
                        mutation.payload.errors.forEach((e: any) => {
                            if (e.code == "UserNotFound") {
                                self.showToastError = true;
                            }
                        });
                    }
                    self.pendingPasswordRecovery = false;
                    subscribtion();
                }
            });
        }, 500);
    }

    sendPartyRegistration() {
        this.sendingPartyRegistration = true;

        setTimeout(() => {
            const sendPartyRegistrationModel: SendPartyRegistrationModel = {
                id: this.client.id
            };
            ApiService.api
                .sendPartyRegistration(sendPartyRegistrationModel, {})
                .then((e: any) => {
                    this.sendingPartyRegistration = false;
                    this.client.registrationEmailSent = true;
                })
                .catch(() => {
                    this.sendingPartyRegistration = false;
                });
        }, 500);
    }

    deleteClientClick() {
        this.deleteClientDialog = true;
    }

    deleteClient() {
        partiesStore.dispatch(
            CrudAction.Delete,
            createCrudQueryPayload<PartyModel>(undefined, [{ field: "id", op: "eq", comparand: this.client.id! }])
        );
        const self = this;
        this.deletingUser = true;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === partiesStore.getActionName(CrudResponseAction.DeleteSuccess)) {
                subscribtion();
                self.deletingUser = false;
                self.$router.push({ name: "Clients" });
            } else if (mutation.type === partiesStore.getActionName(CrudResponseAction.DeleteFailed)) {
                self.deletingUser = false;
                subscribtion();
            }
        });
    }

    archiveClientClick() {
        this.archiveClientDialog = true;
    }

    archiveClient() {
        const client = { ...this.client };
        client.isArchived = !client.isArchived;
        partiesStore.dispatch(CrudAction.Update, { item: client, id: this.client.id });
        const self = this;
        this.archivingUser = true;
        const subscribtion = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === partiesStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                subscribtion();
                self.archivingUser = false;
                self.archiveClientDialog = false;
                self.client.isArchived = client.isArchived;
            } else if (mutation.type === partiesStore.getActionName(CrudResponseAction.UpdateFailed)) {
                self.archivingUser = false;
                subscribtion();
            }
        });
    }

    beforeInsertChild(e: any) {
        e.id = Guid.EmptyGuid();
        e.partyId = this.client.id;
        return e;
    }

    saveChild() {
        console.log("saveChild");
    }

    deleteChild() {
        console.log("deleteChild");
    }
}
