






























































































































import ApiService from "@/libs/core/api-service"
import { ApplicationUserModel, AvatarUploadModel } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import {Component, Prop, Vue, Ref} from 'vue-property-decorator';
import { applicationUserStore } from '../+state/store';

import AccountTypeSelect from '@/libs/common/components-smart/AccountTypeSelect.vue';
import { CrudAction, CrudResponseAction } from '@/libs/core/+state/models/crud-action';

@Component({
    components: {
        AccountTypeSelect
    }
})
export default class UserSettingsGeneral extends Vue {

    notificationSettings: any = {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false
    };
    valid = false;
    basicRules = [
		(v: any) => !!v || 'Toto pole je povinné.'
    ];
    calendarLinkOnLoad : string | null | undefined = null;
    showCalendarLinkToastSuccess = false;
    uploadedFiles = [];
    uploadError = null
    currentStatus = null;
    uploadFieldName = 'photos';
    isSaving = false;
    fileTooBig = false;

    @Ref() form!: any;
    @Ref() uploadAvatarInput!: any;
    
    get saving (): boolean {
        return applicationUserStore.useGetter(CrudGetter.Saving);
    }

    get user(): ApplicationUserModel {
        const user = {...applicationUserStore.useGetter(CrudGetter.Detail)} as ApplicationUserModel;
        this.calendarLinkOnLoad = user.calendarLink;
        return user;
    }

    save () {
        if (this.form.validate()) {
            applicationUserStore.dispatch(CrudAction.Update, { item: this.user });
            const self = this;
            const subscribtion = this.$store.subscribe((mutation, _state) => {
                if (mutation.type === applicationUserStore.getActionName(CrudResponseAction.UpdateSuccess)) {
                    subscribtion();
                    if (self.calendarLinkOnLoad != mutation.payload.item.calendarLink && 
                        mutation.payload.item.calendarLink != null && mutation.payload.item.calendarLink.length > 0) {
                        self.showCalendarLinkToastSuccess = true;
                    }
                }
                else if (mutation.type === applicationUserStore.getActionName(CrudResponseAction.UpdateFailed)) {
                    subscribtion();
                }
            });
        }
    }

    getFileBase64(file: File, callback: any) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            callback(reader.result!.toString().split(',')[1]);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    filesChange (fieldName: string, fileList: FileList) {

        this.fileTooBig = false;

        if (!fileList.length) return;

        if(fileList[0].size > 10000000) {
            this.fileTooBig = true;
            return;
        }

        this.getFileBase64(fileList[0], (e: string) => {

            const uploadModel = {
                contentType: fileList[0].type,
                base64: e
            } as AvatarUploadModel;
            this.uploadAvatarInput.value = null;
            ApiService.api.updateAvatar(uploadModel).then((e: any) => {
                applicationUserStore.dispatch(CrudAction.Get, {});
            }).catch((e: any) => {
                throw e;
            });

        });
        
    }

    uploadAvatarClick (e: any) {
        e.preventDefault();
        this.uploadAvatarInput.click();
    }

    deleteAvatarClick () {
        this.user.avatar = null;
        const uploadModel = {
            contentType: null,
            base64: null
        } as AvatarUploadModel;
        ApiService.api.updateAvatar(uploadModel).then((e: any) => {
            applicationUserStore.dispatch(CrudAction.Get, {});
        }).catch((e: any) => {
            throw e;
        });
    }

}
