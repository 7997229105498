




























































import { ArticleModel } from "@/libs/Api";
import { Component, Ref, Vue } from "vue-property-decorator";
import { CrudAction } from "@/libs/core/+state/models/crud-action";
import { createCrudQueryPayload } from "@/libs/core/+state/models/crud-query-payload";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";

import CrudTable from "@/libs/common/components/CrudTable.vue";
import { articlesStore } from "@/libs/articles/+state/store";
import ArticleCreateDialog from "@/libs/system-settings/components/Articles/ArticleCreateDialog.vue";
import ArticleDetailDialog from "@/libs/system-settings/components/Articles/ArticleDetailDialog.vue";

@Component({
    components: { ArticleDetailDialog, ArticleCreateDialog }
})
export default class ArticleList extends Vue {
    @Ref() crudTable!: CrudTable;
    @Ref() articleCreateDialog!: ArticleCreateDialog;
    @Ref() articleDetailDialog!: ArticleDetailDialog;

    get items(): ArticleModel[] {
        return articlesStore.useGetter(CrudGetter.Data);
    }

    get pending(): boolean {
        return articlesStore.useGetter(CrudGetter.Pending);
    }

    mounted(): void {
        this.onLoadItems();
    }

    onLoadItems(): void {
        articlesStore.dispatch(
            CrudAction.GetAll,
            createCrudQueryPayload<ArticleModel>([{ field: "publishedAt", index: 1, order: "desc" }])
        );
    }

    onCreateArticleClick(): void {
        this.articleCreateDialog.open();
    }

    onArticleDetailClick(id: string): void {
        this.articleDetailDialog.open(id);
    }

	onArticleCreated(article: ArticleModel): void {
		this.onLoadItems();
		this.onArticleDetailClick(article.id);
	}

}
