












































































































































































import { ApplicationUserModel, PartyModel } from '@/libs/Api';
import {Component, Vue, Prop, Watch, Ref} from 'vue-property-decorator';
import { partiesStore } from '@/libs/parties/+state/store';
import { CrudAction } from '@/libs/core/+state/models/crud-action';
import { createCrudQueryPayload } from '@/libs/core/+state/models/crud-query-payload';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { LocalStorageHelper } from '@/libs/common/functions/local-storage-helper';

import CodebookSelect from '@/libs/common/components-smart/CodebookSelect.vue';
import { CrudQueryPredicate } from '@/libs/core/+state/models/crud-query-predicate';
import ClientAddDialog from '@/libs/clients/components/ClientAddDialog.vue';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import { TableOptionsModel } from '@/libs/common/models/table-options-model';
import { CrudDataStats } from '@/libs/core/+state/models/crud-data-stats';

@Component({
    components: {
        CodebookSelect,
        ClientAddDialog
    }
})
export default class Clients extends Vue {

    headers = [
        { text: 'Jméno a příjmení', align: 'left', sortable: false, value: 'displayName' },
        { text: 'Kód klienta', align: 'left', sortable: false, value: 'clientCode' },
        { text: 'Datum narození', align: 'left', sortable: false, value: 'birthdate' },
        { text: 'Telefon', align: 'left', sortable: false, value: 'phoneNumber' },
        { text: 'Email', align: 'left', sortable: false, value: 'email' },
        { text: 'Vlastník', align: 'left', sortable: false, value: 'owner.displayName' },
        { text: 'Stav', align: 'left', sortable: false, value: 'clientState.name' },
        { text: 'Odeslány přístupy', align: 'left', sortable: false, value: 'registrationEmailSent' },
        { text: '', align: 'right', sortable: false, value: 'actions' }
    ];
    headersMobile = [
        { text: 'Jméno a příjmení', align: 'left', sortable: false, value: 'displayName' },
        { text: 'Telefon', align: 'left', sortable: false, value: 'phoneNumber' },
        { text: 'Vlastník', align: 'left', sortable: false, value: 'owner.displayName' },
        { text: '', align: 'right', sortable: false, value: 'actions' }
    ];
    ownerId: string | null = null;
    clientStateId: string | null = null;
    productId: string | null = null;
    showFilters = false;
    archived = false;
    registrationEmailSent = 0;
    pageIndex = 1;
    pageSize = 50;

    @Ref() clientAddDialog!: ClientAddDialog;

    @Watch("ownerId")
    @Watch("clientStateId")
    @Watch("productId")
    @Watch("archived")
    @Watch("registrationEmailSent")
    filtersChanged () {
        this.pageIndex = 1;
        this.loadData();
    }

    @Watch("pageIndex")
    @Watch("pageSize")
    paginationChanged() {
        this.loadData();
    }

    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }

    get items(): PartyModel[] {
        return partiesStore.useGetter(CrudGetter.Data);
    }

    get pendingClients () : boolean {
        return partiesStore.useGetter(CrudGetter.Pending);
    }

    get tableOptions(): TableOptionsModel {
        console.log(LocalStorageHelper.getTableOptionsOrDefault("ClientsTable"));
        return {...LocalStorageHelper.getTableOptionsOrDefault("ClientsTable")};
    }

    get totalRows (): number {
        return ({...partiesStore.useGetter(CrudGetter.DataStats)} as CrudDataStats)?.rowCount;
    }

    @Watch("tableOptions", { deep: true })
    tableOptionsChanged (v: TableOptionsModel) {
        debugger;
        LocalStorageHelper.setTableOptions("ClientsTable", v);
    }

    mounted() {
        this.loadData();
    }

    loadData () {
        partiesStore.dispatch(CrudAction.GetAll, this.getPayload());
    }

    getPayload () {
        let filters: CrudQueryPredicate[] = [
            { field: "AccountType", op: "eq", comparand: "Client" }
        ];
        if (this.ownerId) {
            filters.push({ field: "ownerId", op: "eq", comparand: this.ownerId });
        }
        if (this.clientStateId) {
            filters.push({ field: "clientStateId", op: "eq", comparand: this.clientStateId });
        }
        if (this.productId) {
            filters.push({ field: "PartyProducts.ProductId", op: "eq", comparand: this.productId });
        }
        if (this.registrationEmailSent != 0) {
            filters.push({ field: "registrationEmailSent", op: "eq", comparand: this.registrationEmailSent == 1 });
        }
        filters.push({ field: "isArchived", op: "eq", comparand: this.archived });
        return createCrudQueryPayload<PartyModel>([
            { field: "firstName", index: 1, order: "asc" },
	        { field: "lastName", index: 2, order: "asc" },
	        { field: "companyName", index: 3, order: "asc" },
        ], filters, { pageIndex: this.pageIndex, pageSize: 50 });
    }
    
    rowClick (args: any) {
        if (this.user.accountType != "Client") {
            this.$router.push({ name: "ClientGeneral", params: { id: args.id } });
        } else {
            this.$router.push({ name: "ClientGeneralCW", params: { id: args.id } });
        }
    }

    addClientClick () {
        this.clientAddDialog.open();
    }

    downloadClients () {
        partiesStore.dispatch(CrudAction.Download, this.getPayload());
    }

}
