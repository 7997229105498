

































































































































































































import { Component } from "vue-property-decorator";
import { articlesStore } from "@/libs/articles/+state/store";
import { CrudGetter } from "@/libs/core/+state/models/crud-getter";
import { CrudAction, CrudReponse } from "@/libs/core/+state/models/crud-action";
import { ArticleModel } from "@/libs/Api";
import Page from "@/Page.vue";
import ArticleComments from "@/libs/system-settings/components/Articles/ArticleComments.vue";
import CloudFilesFlat from "@/libs/cloud/components/CloudFilesFlat.vue";
import {cloneDeep} from 'lodash';
import { VueEditor } from "vue2-editor";

import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import { ImageResize } from 'quill-image-resize-module-ts';

@Component({
    components: {
        CloudFilesFlat,
        ArticleComments,
	    // Editor,
	    VueEditor
    }
})
export default class ArticleDetailDialog extends Page {
    public dialog = false;
    public valid = true;

    public originalArticle: ArticleModel = null;
    public article: ArticleModel = null;
    public isLoading = false;
    public deleteTaskDialog = false;

	public customModulesForEditor = [
		{ alias: "imageActions", module: ImageActions },
		{ alias: "imageFormats", module: ImageFormats },
		{ alias: "imageResize", module: ImageResize },
	];

	public toolbar = [
		[{ header: [false, 1, 2, 3, 4, 5, 6] }],
		["bold", "italic", "underline", "strike"],
		[
			{ align: "" },
			{ align: "center" },
			{ align: "right" },
			{ align: "justify" }
		],
		["blockquote", "code-block"],
		[{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
		[{ indent: "-1" }, { indent: "+1" }],
		[{ color: [] }, { background: [] }],
		["link", "image", "video", "formula"],
		["clean"]
	];

    public basicRules = [(v: any) => !!v || "Toto pole je povinné."];

	public content = '<p>Hello from CKEditor 5 in Vue 2!</p>';
	public editorSettings = {
		modules: {
			imageActions: {},
			imageFormats: {},
			imageResize: {
				modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
				displayStyles: {
					margin: '0 16px 16px 0'
				}
			},
		},
		formats: ['align', 'float']
	};

    constructor() {
        super();
    }

    public get creating(): boolean {
        return articlesStore.useGetter(CrudGetter.Saving);
    }

    public get isDeleting(): boolean {
        return articlesStore.useGetter(CrudGetter.Deleting);
    }

    public open(id: string): void {
        this.dialog = true;
        this.isLoading = true;
        articlesStore.dispatch(CrudAction.Get, { id });
        this.subscribe(articlesStore, CrudReponse.Get).then((article: ArticleModel) => {
            this.originalArticle = cloneDeep(article);
            this.article = { ...article };
            this.isLoading = false;
            this.$nextTick(() => {
	            this.content = article.content;
            });
        });
    }

    private close(): void {
        this.dialog = false;
    }

    public onSaveClick(): void {
		this.article.content = this.content;
        articlesStore.dispatch(CrudAction.Update, {
            item: this.article
        });
        this.subscribe(articlesStore, CrudReponse.Update).then(e => {
            this.originalArticle = cloneDeep(e);
            this.article = { ...e };
            this.close();
            this.$emit("updated");
        });
    }

    public onDeleteClick(): void {
        this.deleteTaskDialog = true;
    }

    public onDelete(): void {
        articlesStore.dispatch(CrudAction.Delete, { id: this.article.id });
        this.subscribe(articlesStore, CrudReponse.Delete).then(() => {
            this.close();
        });
    }

    public onFileCountChanged(fileCount: number): void {
        this.originalArticle.fileCount = fileCount;
		this.article.fileCount = fileCount;
        articlesStore.dispatch(CrudAction.Update, { item: this.originalArticle });
    }
}
