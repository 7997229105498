





































































































































import NotificationService from "@/libs/core/notifications/notification-service";
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';

import Sidebar from "@/libs/common/components/Sidebar.vue";

import { authStore } from "@/libs/auth/+store/store";
import { AuthAction, AuthGetter, AuthState } from "@/libs/auth/models/auth-state";
import { CrudAction, CrudResponseAction } from "./libs/core/+state/models/crud-action";
import { ApplicationUserModel, NotificationModel, ProductModel } from "./libs/Api";
import { CrudGetter } from "./libs/core/+state/models/crud-getter";
import { applicationUserStore } from "./libs/user-settings/+state/store";
import { productsStore, productTypesStore } from "./libs/products/+state/store";
import { createCrudQueryPayload } from "./libs/core/+state/models/crud-query-payload";
import { codebooksStore } from "./libs/codebooks/+state/store";
import NotificationDialog from "@/libs/notifications/components/NotificationDialog.vue";
import { notificationsStore } from "./libs/notifications/+state/store";
import moment from "moment";

import Account from "@/libs/account/pages/Account.vue";
import ClientArticleDetailDialog from "@/libs/articles/pages/ClientArticleDetailDialog.vue";

@Component({
    components: {
        ClientArticleDetailDialog,
        Sidebar,
        NotificationDialog,
        Account
    }
})
export default class App extends Vue {
    loading = true;
    loadingUserData = true;
    user: any = {};
    userInfo: ApplicationUserModel | null = null;
    showMobileNav = false;
    accountType = "";
	@Ref() articleDetailDialog: ClientArticleDetailDialog;

    get isLoggedIn(): boolean {
        return authStore.useGetter(AuthGetter.GetIsLoggedIn);
    }

    @Watch("isLoggedIn")
    isLoggedInChanged(v: boolean) {
        if (!v) {
            if (
                this.$route.name != "PasswordRecovery" &&
                this.$route.name != "SignIn" &&
                this.$route.name != "SignUp" &&
                this.$route.name != "SignUpOrganization"
            )
                this.$router.push({ name: "SignIn", query: this.$route.query });
        } else {
            this.connectSignalR();
            this.loadUserData();
        }
    }

    @Watch("$route")
    routeChanged(to: any, from: any) {
        if (this.isLoggedIn && this.accountType == "Client" && to.meta?.clientZone != true) {
            this.$router.replace({ name: "AccountGeneral" });
        }
    }

    mounted() {
        const envKey = process.env.NODE_ENV != "production" ? ".".concat(process.env.NODE_ENV!) : "";
        fetch(process.env.BASE_URL + "config".concat(envKey).concat(".json")).then(result => {
            result.json().then((jsonBody: any) => {
                (window as any).ApiService.baseUrl = jsonBody.ApiEndpoint;
                (window as any).SaasApiEndpoint = jsonBody.SaasApiEndpoint;
                const self = this;
                authStore.dispatch(AuthAction.UserInfo);
                const subscription = this.$store.subscribe((mutation, _state) => {
                    if (mutation.type === authStore.getActionName(AuthAction.UserInfo)) {
                        subscription();
                        self.user = mutation.payload;
                        (window as any).user = self.user;
                        if (
                            !_state.AUTH.isLoggedIn &&
                            self.$route.name !== "PasswordRecovery" &&
                            self.$route.name !== "SignIn" &&
                            self.$route.name !== "SignUp" &&
                            self.$route.name !== "SignUpOrganization"
                        ) {
                            this.loading = false;
                            self.$router.push({ name: "SignIn", query: self.$route.query });
                            window.location.reload();
                        } else if (_state.AUTH.isLoggedIn) {
                            this.loading = false;
                            this.loadUserData();
                        } else {
                            this.loading = false;
                            this.loadingUserData = false;
                            //this.loadUserData();
                            // TODO: duplicit call - commented out
                            // self.connectSignalR();
                        }
                    }
                });
            });
        });
    }

    toggleClick() {
        (this.$refs.dockSidebar as any).toggle();
    }

    connectSignalR() {
        NotificationService.init()
            .then(() => {
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    }

    signout() {
        authStore.dispatch(AuthAction.SignOut);
    }

    loadUserData() {
        this.loadingUserData = true;
        this.loadUser(() => {
            this.loadProductTypes(() => {
                this.loadProducts(() => {
                    this.loadCodebooks(() => {
                        this.loadingUserData = false;
                        if (this.isLoggedIn && this.accountType == "Client" && this.$route.meta?.clientZone != true) {
                            this.$router.replace({ name: "AccountGeneral", query: this.$route.query });
                        }
						if (this.$route.query.article) {
							this.articleDetailDialog.open(this.$route.query.article as string);
						}
                    });
                });
            });
        });
    }

    loadUser(fn: any) {
        applicationUserStore.dispatch(CrudAction.Get, {});
        const self = this;
        this.$store.subscribe((mutation, _state) => {
            if (mutation.type === applicationUserStore.getActionName(CrudResponseAction.GetSuccess)) {
                self.userInfo = mutation.payload.item;
            }
        });
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === applicationUserStore.getActionName(CrudResponseAction.GetSuccess)) {
                self.accountType = self.userInfo!.accountType!;
                subscriber();
                fn();
            }
        });
    }

    loadProductTypes(fn: any) {
        productTypesStore.dispatch(
            CrudAction.GetAll,
            createCrudQueryPayload<ProductModel>([{ field: "name", index: 1, order: "asc" }])
        );
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === productTypesStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                fn();
            }
        });
    }

    loadProducts(fn: any) {
        productsStore.dispatch(
            CrudAction.GetAll,
            createCrudQueryPayload<ProductModel>([{ field: "name", index: 1, order: "asc" }])
        );
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === productsStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                fn();
            }
        });
    }

    loadCodebooks(fn: any) {
        codebooksStore.dispatch(CrudAction.GetAll, createCrudQueryPayload());
        const self = this;
        const subscriber = this.$store.subscribe((mutation, _state) => {
            if (mutation.type === codebooksStore.getActionName(CrudResponseAction.GetAllSuccess)) {
                subscriber();
                fn();
            }
        });
    }

    get notifications(): NotificationModel[] {
        return notificationsStore.useGetter(CrudGetter.Data);
    }

    loadNotifications() {
        notificationsStore.dispatch(
            CrudAction.GetAll,
            createCrudQueryPayload<NotificationModel>(
                [{ field: "time", index: 1, order: "desc" }],
                [
                    { field: "ownerId", op: "eq", comparand: this.userInfo!.partyId! },
                    { field: "time", op: "lte", comparand: moment().format("YYYY-MM-DDT23:59:59") }
                ]
            )
        );
    }

    toggleNavbar() {
        this.showMobileNav = !this.showMobileNav;
    }
}
