











































import { ApplicationUserModel } from '@/libs/Api';
import { CrudGetter } from '@/libs/core/+state/models/crud-getter';
import { applicationUserStore } from '@/libs/user-settings/+state/store';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({ })
export default class SystemSettings extends Vue {
    get user(): ApplicationUserModel {
        return applicationUserStore.useGetter(CrudGetter.Detail);
    }
}
