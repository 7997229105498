import {
    HttpResponse,
    ArticleReadingModel,
    RequestParams,
    ApiResultFailed,
    Operation,
    AggregationModel,
    AggregationResponse,
    GrouppedQueryModel
} from "@/libs/Api";
import ApiService from "@/libs/core/api-service"
import {CrudDataQuery, CrudService} from '@/libs/core/+state/models/crud-service';
import {CrudEnumerableResponse, CrudResponse} from "../core/+state/models/crud-response";

export class ArticleReadingsDataService implements CrudService<ArticleReadingModel, ArticleReadingModel> {
    
    getAggregation(query: AggregationModel, params?: RequestParams): Promise<AggregationResponse> {
        throw new Error("Method not implemented.");
    }
    
    getGroupped(query: GrouppedQueryModel, params?: RequestParams): Promise<CrudResponse<any>> {
        throw new Error("Method not implemented.");
    }
    
    getAll(query: CrudDataQuery<ArticleReadingModel>): Promise<CrudEnumerableResponse<ArticleReadingModel>> {
        return ApiService.api.getArticleReadingBatch(query.pageIndex, query.pageSize, <any>query.data, query.params);
    }

    get(id: string, params?: RequestParams): Promise<CrudResponse<ArticleReadingModel>> {
        return ApiService.api.getArticleReadingById(id, params);
    }

    create(item: ArticleReadingModel, params?: RequestParams): Promise<CrudResponse<ArticleReadingModel>> {
        return ApiService.api.insertArticleReading(item, params);
    }

    update(item: ArticleReadingModel, params?: RequestParams): Promise<ArticleReadingModel> {
        return ApiService.api.updateArticleReading(item.id as Required<string>, item, params).then((req: any) => req.data.returnValue);
    }

    partialUpdate(id: string, patches: Operation[], params?: RequestParams): Promise<ArticleReadingModel> {
        return ApiService.api.patchArticleReading(id, patches).then((req: any) => req.data.returnValue);
    }

    delete(query: CrudDataQuery<ArticleReadingModel>): Promise<CrudEnumerableResponse<ArticleReadingModel>> {
        return ApiService.api.deleteArticleReadings(<any>query.data, query.params).then((req: any) => req.data.returnValue);
    }

    download(query: CrudDataQuery<ArticleReadingModel>): Promise<HttpResponse<any, ApiResultFailed | void>> {
        throw "NotImplemented";
    }

}
